<div class="all-content">
    <div class="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-sm-12 col-xs-12">
        <div class="row">
            <mat-card class="col-lg-7 col-md-7 col-sm-12 col-xs-12 margin-bt">

                <div mat-card-image class="card-image center-cropped"
                    style="background-image: url({{data.cover.host+data.cover.full}});">
                    <div class="cover-shadow"></div>
                </div>
                <!-- <agm-map mat-card-image *ngIf="!coverImage && candidateState && candidateCity" [latitude]="lat"
                    [longitude]="long" [zoom]="12" [styles]="darkStyle" [disableDefaultUI]="true"></agm-map> -->
                <div class="info-container">
                    <div class="row">
                        <div class="info-header col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="row">
                                <div class="avatar col-lg-3 col-md-3 col-sm-3 col-xs-12"
                                    *ngIf="data.typeRef == 'Eleitoral'">
                                    <div class="avatar-img center-cropped"
                                        style="background-image: url({{data.image.host+data.image.full}});">
                                    </div>
                                </div>
                                <div class="candidate-info col-lg-8 col-md-8 col-sm-8 col-xs-12">
                                    <h1 class="lowercase">{{ data.title }}<img class="icon-verificado"
                                            src="../../assets/verificado.png"> </h1>
                                    <!-- <h1>{{ data.nameCandidate }}</h1> -->

                                    <div class="pfd-top" *ngIf="data.typeRef == 'Eleitoral'">
                                        <div class="profile-details">
                                            <span>Candidato a</span>
                                            {{ data.cargo }}
                                        </div>
                                        <div class="profile-details">
                                            <span>Concorre por</span>
                                            {{ data.where }}
                                        </div>
                                        <div class="profile-details" lang="pt">
                                            <span>partido</span>
                                            {{ data.partido }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class=" col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="info-body" *ngIf="data.typeRef == 'Eleitoral'">
                                <div class="tags" *ngIf="hasFlag">
                                    <div *ngIf="!expandFlags">
                                        <span>Bandeiras</span>
                                        <div class="flags" *ngFor="let flag of data.flags | slice:0:3; index as i">
                                            {{ flag }}
                                            <!-- <li>EDUCAÇÃO</li>
                                            <li>SANEAMENTO</li>
                                            <li>SAÚDE</li> -->
                                        </div>
                                        <div class="flags expander" *ngIf="data.flags.length > 3"
                                            (click)="expandFlags = true">mostrar mais</div>
                                    </div>
                                    <div *ngIf="expandFlags">
                                        <span>Bandeiras</span>
                                        <div class="flags" *ngFor="let flag of data.flags; index as i">
                                            {{ flag }}
                                            <!-- <li>EDUCAÇÃO</li>
                                            <li>SANEAMENTO</li>
                                            <li>SAÚDE</li> -->
                                        </div>
                                        <div class="flags expander" (click)="expandFlags = false">Mostrar Menos</div>
                                    </div>
                                </div>
                            </div>
                            <!-- {{data.socialMedias.youtubevideo}} -->
                            <!-- {{newVideoUrl}} -->
                            <div *ngIf="data.socialMedias && data.socialMedias.youtubevideo" class="video">
                                <iframe width="560" height="315" [src]="treatVideoUrl(data.socialMedias.youtubevideo)"
                                title="YouTube video player" frameborder="0" allow="clipboard-write; encrypted-media; gyroscope;
                                picture-in-picture; web-share"
                                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                            <div class="info-body">
                                <div class="social-media"
                                    *ngIf="data.socialMedias.facebook || data.socialMedias.twitter || data.socialMedias.instagram || data.socialMedias.youtube">
                                    <span>Redes</span>
                                    <ul>
                                        <li>
                                            <a *ngIf="data.socialMedias && data.socialMedias.facebook" target="_blank"
                                                [href]="'https://www.facebook.com/'+data.socialMedias.facebook">
                                                <fa-icon [icon]="faFacebook"></fa-icon>
                                            </a>
                                        </li>
                                        <li>
                                            <a *ngIf="data.socialMedias && data.socialMedias.twitter" target="_blank"
                                                [href]="'https://www.twitter.com/'+data.socialMedias.twitter">
                                                <fa-icon [icon]="faTwitter"></fa-icon>
                                            </a>
                                        </li>
                                        <li>
                                            <a *ngIf="data.socialMedias && data.socialMedias.instagram" target="_blank"
                                                [href]="'https://www.instagram.com/'+data.socialMedias.instagram">
                                                <fa-icon [icon]="faInstagram"></fa-icon>
                                            </a>
                                        </li>
                                        <li>
                                            <a *ngIf="data.socialMedias && data.socialMedias.youtube" target="_blank"
                                                [href]="'https://www.youtube.com/@'+data.socialMedias.youtube">
                                                <fa-icon [icon]="faYoutube"></fa-icon>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="bios" [innerHTML]="data.description">
                            <!-- <figure class="media"><oembed url="https://www.youtube.com/watch?v=mFtFrle7_F4"></oembed></figure> -->
                        </div>
                    </div>
                </div>

            </mat-card>

            <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                <mat-card class="col-lg-12 col-md-12 col-sm-12 col-xs-12 donate">
                    <div class="info-container">
                        <div class="row">
                            <div class="info">
                                <div class="value text-center">
                                    <h3>total arrecadado</h3>
                                    <h1>{{ data.totalCollected | currency : 'R$' }}</h1>
                                    <h3 *ngIf="data.amountDonation > 0">{{ data.amountDonation }}
                                        pessoas <strong>já
                                            doaram!</strong></h3>
                                    <h3 *ngIf="data.amountDonation == 0">Seja
                                        <strong>o primeiro</strong> a doar!
                                    </h3>
                                </div>
                                <div *ngIf="isOwner()" routerLink='/admin/painel-doacoes' class="success">
                                    <h3> Clique <u>aqui</u> para visualizar suas doações recebidas </h3>
                                </div>

                                <div class="card-percent">

                                    <div class="percent" *ngIf="data.meta">
                                        <div>
                                            <p>{{ data.hitTarget > 100 ? 100 : data.hitTarget }}%</p>
                                        </div>
                                        <div class="barra">
                                            <div style="width: {{data.hitTarget > 100 ? 100 : data.hitTarget}}%"></div>
                                        </div>
                                        <div class="meta">{{ data.meta | currency : 'R$' }}</div>
                                    </div>


                                </div>
                                <div class="info-body">
                                    <div class="buttons">
                                        <div class="donates">
                                            <div *ngIf="data && data.valueDonation && data.valueDonation.length != 0"
                                                class="row">
                                                <div *ngFor="let value of data.valueDonation"
                                                    class="col-xs-12 col-sm-12 col-md-3 col-xs-3">
                                                    <button (click)="donateValue = value"
                                                        [ngClass]="{ active: donateValue == value }" mat-flat-button>R$
                                                        {{ value }}</button>
                                                </div>
                                            </div>
                                            <div *ngIf="anotherValue" class="row">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <form class="value-form form">
                                                        <span class="warning" *ngIf="checkValueInput">
                                                            O valor deve ser entre R$20 e R$1064.
                                                        </span>
                                                        <mat-form-field appearance="outline" class="form-field">
                                                            <mat-label>Insira o valor aqui</mat-label>
                                                            <input (keyup)="onKeyup($event)" [(ngModel)]="donateValue"
                                                                type="number" matInput [autofocus]=true
                                                                placeholder="Valor Mínimo: R$20"
                                                                (blur)="setDonateValue($event)">
                                                        </mat-form-field>
                                                    </form>
                                                </div>
                                            </div>
                                            <div *ngIf="!anotherValue" class="row padding">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <button (click)="anotherValue = true"
                                                        [ngClass]="{ active: anotherValue == true }"
                                                        style="margin-right: 0;" mat-flat-button> quero doar outro
                                                        valor</button>
                                                </div>
                                            </div>
                                            <div *ngIf="anotherValue" class="row padding">
                                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <button (click)="anotherValue = false" class="active"
                                                        style="margin-right: 0;" mat-flat-button>voltar</button>
                                                </div>
                                            </div>
                                        </div>
                                        <button class="redirect-button green" mat-flat-button
                                            [disabled]="checkValueInput || donateValue < 10" (click)="goDonation()"><img
                                                class="icon" src="../../assets/porquinho2.png">doar agora!</button>
                                        <p class="link text-center">

                                        </p>
                                        <p>
                                            {{data.vaquinhaTermsV2}}
                                        </p>
                                        <!-- <p class="little">O valor mínimo para doação é R$20,00 via cartão de crédito e pix. Pagamentos utilizando Boleto são habilitados para doações maiores ou iguais a R$60,00. <br>

                                                    De acordo com regras do TSE, só são permitidas doações diárias de um mesmo CPF, utilizando cartão de crédito ou boleto, para um mesmo candidato até o valor máximo de R$1.064,09. Após 24 horas, o mesmo limite poderá ser novamente doado. <br>

                                                    Conforme a resolução do TSE 23.553, Art. 23 . II – identificação obrigatória, com o nome completo e o número de inscrição no cadastro de pessoas físicas (CPF) de cada um dos doadores, o valor das quantias doadas individualmente, forma de pagamento e as datas das respectivas doações. <b class="bold">Precisamos exibir todos esses dados em lista pública já que sem o CPF poderiam haver homônimos. </b>Sites de financiamento coletivo que não respeitarem esta norma terão problemas com a justiça eleitoral.</p> -->
                                    </div>
                                    <button *ngIf="data.hasOwnProperty('cargo')" class="redirect-button new-btn"
                                        mat-flat-button (click)="getDonationsList(data._id)"> Transparência</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </mat-card>
                <mat-card class="col-lg-12 col-md-12 col-sm-12 col-xs-12 share">
                    <div>
                        <div class="info">
                            <div class="text-center">
                                <h3><strong>Compartilhe!</strong></h3>
                                <div class="url">
                                    <h3>queroapoiar.com.br/{{ data.url }}</h3>
                                    <button mat-flat-button class="copy"
                                        (click)='copyToClipboard(data.url)'><strong>copiar</strong></button>
                                </div>
                                <div class="links">
                                    <a target="_blank"
                                        href="https://www.facebook.com/sharer/sharer.php?u=https://www.queroapoiar.com.br/{{ data.url }}">
                                        <fa-icon [icon]="faFacebook"></fa-icon>
                                    </a>
                                    <a target="_blank"
                                        href="https://twitter.com/intent/tweet?url=https://www.queroapoiar.com.br/{{ data.url }}"
                                        data-show-count="false">
                                        <fa-icon [icon]="faTwitter"></fa-icon>
                                    </a>
                                    <a target="_blank"
                                        href="https://api.whatsapp.com/send?text=https://www.queroapoiar.com.br/{{ data.url }}"
                                        data-action="share/whatsapp/share">
                                        <fa-icon [icon]="faWhatsapp"></fa-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
    <div class="bg-white-profile">aaaa</div>
</div>
